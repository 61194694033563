<template>
<div v-if="!postArray.error" v-on:click="click">
	<div class="EntitySingle">
		<Header :deviceHeader="device" />
		<div class="lg:flex px-10">
			<div class="lg:w-6/12 text-7xl px-2">
				<div class="w-full text-center sticky top-20">
					<Gallery />
				</div>
			</div>
			<div class="lg:w-6/12 h-full px-2">
				<div v-if="device == 'tv'">
					<BackToList />
				</div>
				<div v-else-if="device == 'tablet'">
					<BackToList />
				</div>
				<div class="z-1 pb-10">
					<h2 class="font-heading text-h2 text-sepiaBlack dark:text-halfColonialWhite py-6">{{ postArray.title }}</h2>
                	<Button v-if="content.game_link" :text="$t('entity.startGame')" buttonType="secondary" :href="content.game_link" fullWidth class="pb-4"/>
					<p class="text-sepiaBlack dark:text-white">{{ postArray.text }}</p>
					<p class="font-heading text-subM text-twine font-extrabold py-6">{{ $t('entity.description') }}</p>
					<p v-html="postArray.description" class="text-sepiaBlack dark:text-white mx-auto description"></p>
					<div v-show="postArray.story">
						<p class="font-heading text-subM text-twine font-extrabold py-6">{{ $t('entity.story') }}</p>
						<p v-html="postArray.story" class="text-sepiaBlack dark:text-white mx-auto story"></p>
					</div>
				</div>
				<Navigation :entity_id="postArray.entity_id" :device="device" class="z-2 sticky bottom-10 justify-end saveButton"/>
			</div>
		</div>
	</div>
</div>
<div v-else>
  <h1>ERROR! ELEMENT NOT FOUND!</h1>
</div>
</template>

<script>

import Header from "@/components/Header.vue";
import BackToList from "@/components/tablet/BackToList.vue";
import Navigation from "@/components/tablet/Navigation.vue";
import Gallery from '@/components/Gallery.vue';
import Button from '@/components/Button.vue';

export default {
	name: 'EntitySingle',
	components: {
		BackToList,
		Header,
		Navigation,
		Gallery,
		Button,
	},
	props: {
		content: {
			type: Object,
			require: true,
			default: () => {}
		},
		device: {
			type: String,
			require:false,
		}
	},
	data(){
        return {
            seconds: 60,
			standard_sec: 60,
        }
    },
	mounted() {
		const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null;
  		if (!authUser) {
        	this.startTimer();
		}
    },
	created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
	computed: {
		postArray() {
			return {
				title: this.content?.title,
				text: this.content?.sport_content,
				src: this.content?.img,
				alt: this.content?.alt,
				description: this.content?.description,
				link: window.location.pathname + '/' + this.content?.id,
				error: this.content?.error,
				entity_id: this.content?.id,
				story: this.content?.story,
				game_link: this.content?.game_link,
			}
		}
  	},
	methods: {
        startTimer() {
            this.seconds = this.standard_sec;
            setTimeout(this.countdown, 1000);
        },
        countdown() {
            // console.log(this.seconds);
            if (this.seconds > 0) {
                this.seconds -= 1;
                setTimeout(this.countdown, 1000);
            } else {
				this.$router.push('/' + this.device + 's?category=' + localStorage.getItem(this.device + '_category') + '&seconds=0');
			}
        },
        click() {
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
        handleScroll (event) {
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
    },
}
</script>

<style>
.description img,
.story img {
	margin: 0 auto !important;
}
</style>